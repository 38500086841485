import React, { useState, useEffect } from 'react';
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import {
  ChartCanvas,
  Chart,
  CandlestickSeries,
  XAxis,
  YAxis,
  discontinuousTimeScaleProviderBuilder,
  OHLCTooltip,
  lastVisibleItemBasedZoomAnchor,
  ema,
  LineSeries,
  MouseCoordinateX,
  MouseCoordinateY,
  CrossHairCursor
} from "react-financial-charts";
import { Bell, User, Settings, BarChart2, Trophy, Gift, Users, LogOut } from 'lucide-react';

const generateCandleData = (basePrice, volatility) => {
  const open = basePrice + (Math.random() - 0.5) * volatility;
  const close = basePrice + (Math.random() - 0.5) * volatility;
  const high = Math.max(open, close) + Math.random() * volatility / 2;
  const low = Math.min(open, close) - Math.random() * volatility / 2;
  return { open, high, low, close, date: new Date() };
};

const DiginexSimulation = ({ width = 800 }) => {
  const [price, setPrice] = useState(55700);
  const [candleHistory, setCandleHistory] = useState([]);
  const [investment, setInvestment] = useState(50);
  const [balance, setBalance] = useState(1000);
  const [timeLeft, setTimeLeft] = useState(9);

  useEffect(() => {
    const interval = setInterval(() => {
      const newCandle = generateCandleData(price, 50);
      setPrice(newCandle.close);
      setCandleHistory(prev => [...prev.slice(-99), newCandle]);
      setTimeLeft(prev => (prev > 0 ? prev - 1 : 9));
    }, 1000);

    return () => clearInterval(interval);
  }, [price]);

  const handleTrade = (direction) => {
    setTimeout(() => {
      const outcome = Math.random() > 0.5;
      setBalance(prev => outcome ? prev + investment : prev - investment);
    }, 10000);
  };

  const ScaleProvider = discontinuousTimeScaleProviderBuilder().inputDateAccessor(
    d => d.date
  );
  const { data, xScale, xAccessor, displayXAccessor } = ScaleProvider(candleHistory);

  const pricesDisplayFormat = format(".2f");
  const xExtents = [
    xAccessor(data[Math.max(0, data.length - 100)]),
    xAccessor(data[data.length - 1])
  ];

  const ema12 = ema()
    .id(1)
    .options({ windowSize: 12 })
    .merge((d, c) => { d.ema12 = c; })
    .accessor(d => d.ema12);

  const ema26 = ema()
    .id(2)
    .options({ windowSize: 26 })
    .merge((d, c) => { d.ema26 = c; })
    .accessor(d => d.ema26);

  return (
    <div style={{ backgroundColor: '#0a0e17', color: '#ffffff', minHeight: '100vh', display: 'flex', fontFamily: 'Arial, sans-serif' }}>
      {/* Left Sidebar */}
      <div style={{ width: '60px', backgroundColor: '#1a2030', padding: '20px 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <BarChart2 size={24} style={{ marginBottom: '20px' }} />
        <Trophy size={24} style={{ marginBottom: '20px' }} />
        <Gift size={24} style={{ marginBottom: '20px' }} />
        <Users size={24} style={{ marginBottom: '20px' }} />
        <LogOut size={24} style={{ marginTop: 'auto' }} />
      </div>

      {/* Main Content */}
      <div style={{ flex: 1, padding: '20px' }}>
        {/* Header */}
        <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <div style={{ fontSize: '24px', fontWeight: 'bold' }}>DIGINEX</div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <span>Tài khoản Thực: ${balance.toFixed(2)}</span>
            <button style={{ backgroundColor: '#10B981', color: '#ffffff', padding: '8px 16px', borderRadius: '4px', border: 'none', cursor: 'pointer' }}>Nạp Nhanh</button>
            <Bell size={20} />
            <User size={20} />
            <Settings size={20} />
          </div>
        </header>

        {/* Chart and Trading Panel */}
        <div style={{ display: 'flex', gap: '20px' }}>
          {/* Chart */}
          <div style={{ flex: 1, backgroundColor: '#1a2030', padding: '20px', borderRadius: '8px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
              <div>
                <span style={{ fontWeight: 'bold', fontSize: '18px' }}>BTC/USDT</span>
                <span style={{ marginLeft: '16px', fontSize: '18px' }}>${price.toFixed(2)}</span>
              </div>
              <div style={{ fontSize: '16px' }}>{timeLeft}s</div>
            </div>
            <ChartCanvas
              height={400}
              ratio={1}
              width={width}
              margin={{ left: 50, right: 50, top: 10, bottom: 30 }}
              data={data}
              displayXAccessor={displayXAccessor}
              seriesName="BTCUSD"
              xScale={xScale}
              xAccessor={xAccessor}
              xExtents={xExtents}
              zoomAnchor={lastVisibleItemBasedZoomAnchor}
            >
              <Chart id={1} yExtents={d => [d.high, d.low]}>
                <XAxis axisAt="bottom" orient="bottom" />
                <YAxis axisAt="left" orient="left" ticks={5} />
                <CandlestickSeries />
                <LineSeries yAccessor={ema12.accessor()} stroke={ema12.stroke()} />
                <LineSeries yAccessor={ema26.accessor()} stroke={ema26.stroke()} />
                <MouseCoordinateY
                  at="left"
                  orient="left"
                  displayFormat={pricesDisplayFormat}
                />
                <MouseCoordinateX
                  at="bottom"
                  orient="bottom"
                  displayFormat={timeFormat("%Y-%m-%d")}
                />
                <OHLCTooltip origin={[-40, 0]} />
              </Chart>
              <CrossHairCursor />
            </ChartCanvas>
          </div>

          {/* Trading Panel */}
          <div style={{ width: '300px', backgroundColor: '#1a2030', padding: '20px', borderRadius: '8px' }}>
            <h3 style={{ fontWeight: 'bold', marginBottom: '15px', fontSize: '18px' }}>Giao dịch</h3>
            <input 
              type="number" 
              value={investment}
              onChange={(e) => setInvestment(Number(e.target.value))}
              style={{ width: '100%', backgroundColor: '#2a3040', padding: '10px', borderRadius: '4px', color: '#ffffff', fontSize: '16px', marginBottom: '15px', border: 'none' }}
            />
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
              <button onClick={() => handleTrade('up')} style={{ backgroundColor: '#26a69a', padding: '10px', borderRadius: '4px', fontSize: '16px', color: '#ffffff', border: 'none', cursor: 'pointer' }}>MUA</button>
              <button onClick={() => handleTrade('down')} style={{ backgroundColor: '#ef5350', padding: '10px', borderRadius: '4px', fontSize: '16px', color: '#ffffff', border: 'none', cursor: 'pointer' }}>BÁN</button>
            </div>
          </div>
        </div>

        {/* Indicators */}
        <div style={{ marginTop: '20px', backgroundColor: '#1a2030', padding: '20px', borderRadius: '8px' }}>
          <h3 style={{ fontWeight: 'bold', marginBottom: '15px', fontSize: '18px' }}>Indicators</h3>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {['Oscillators', 'Summary', 'Moving Averages'].map(indicator => (
              <div key={indicator} style={{ textAlign: 'center' }}>
                <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>{indicator}</div>
                <div style={{ width: '100px', height: '100px', borderRadius: '50%', border: '5px solid #2a3040', margin: '0 auto', position: 'relative' }}>
                  <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>NEUTRAL</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiginexSimulation;